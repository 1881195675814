<template>
  <div>
    <p class="h4 pt-3 pb-4">{{$route.name}}</p>
    <p v-if="routeName === 'edit'">序号： {{$route.query.id}}</p>
    <el-form ref="form" :rules="rules" :model="form" label-width="80px" :inline="true" size="small">
      <el-form-item label="项目名称" prop="name" style="width: 48%;">
        <el-input class="form-item-width" type="text" v-model="form.name" placeholder="填写项目名称"></el-input>
      </el-form-item>
      <el-form-item label="项目类型" prop="type" style="width: 48%;">
        <el-select v-model="form.type" placeholder="选择项目类型">
          <el-option v-for="item in soptype" :key="item.name" :label="item.name" :value="item.sopType"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目要求" prop="category" style="width: 48%;">
        <el-select v-model="form.category" placeholder="选择项目要求">
          <el-option label="个人" :value="1"></el-option>
          <el-option label="团队" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单价" style="width: 48%;">
        <div style="display: flex;">
          <el-input class="form-item-width" type="text" v-model.number="form.price" placeholder="填写单价" style="flex: 1;"></el-input>
          <span class="ml-2">泛函币</span>
        </div>
      </el-form-item>
      <el-form-item label="截止日期" prop="deadline" style="width: 48%;">
        <el-date-picker
          class="form-item-width"
          v-model="form.deadline"
          value-format="timestamp"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="联系方式" prop="contact" style="width: 48%;">
        <el-input class="form-item-width" type="text" v-model="form.contact" placeholder="填写联系方式（邮箱）"></el-input>
      </el-form-item>
      <p class="el-form-item__label">项目详情</p>
      <Editor v-model="detail"></Editor>
      <el-upload
        action="https://upload.qiniup.com"
        :before-upload='beforeUpload'
        :on-success='uploadSuccess'
        :data="uploadData"
        accept="image/*"
        ref="imageupload"
        style="display:none">
        <el-button id="imgInput" size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-upload
        action="https://upload.qiniup.com"
        :before-upload='beforeUpload'
        :on-success='uploadSuccess'
        :data="uploadData"
        accept="video/*"
        ref="videoupload"
        style="display:none">
        <el-button id="videoInput" size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-form-item class="mt-5">
        <el-button type="primary" @click="onSubmit('form')" v-if="routeName === 'add'">添加</el-button>
        <el-button type="primary" @click="onSubmit('form')" v-else>修改</el-button>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Editor from '@/components/Editor'
import { getRecruit, addRecruits, updateRecruits } from '@/api/tips'
import soptype from '@/lib/types/soptype'

export default {
  name: 'recruit-edit',
  components: {
    Editor
  },
  data () {
    return {
      subject: '',
      tipId: '',
      soptype: soptype,
      form: {
        name: '',
        deadline: '',
        price: '',
        type: '',
        category: '',
        contact: ''
      },
      rules: {
        deadline: [
          { required: true, message: '请选择截止日期', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择项目类型', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择项目要求', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { type: 'email', message: '请输入邮箱格式', trigger: 'blur' }
        ]
      },
      detail: ''
    }
  },
  computed: {
    routeName () {
      return this.$route.meta.name
    }
  },
  mounted: function () {
    this.tipId = this.$route.query.id
    if (this.routeName === 'edit') this.getTip()
  },
  methods: {
    /**
     * 编辑情况下的get
     */
    getTip () {
      getRecruit(this.tipId).then(
        res => {
          const {
            name,
            deadline,
            price,
            type,
            category,
            contact,
            detail
          } = res.data
          this.form = {
            name,
            deadline: deadline * 1000,
            type,
            category,
            contact
          }
          if (price !== '') {
            this.form.price = price
          }
          this.detail = detail || ''
        }
      )
    },
    /**
     * 提交验证
     */
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.routeName === 'add') this.addInfo()
          else this.updateInfo()
        } else {
          return false
        }
      })
    },
    /**
     * 提交的数据
     */
    setJson () {
      const json = {
        ...this.form
      }
      json.deadline = json.deadline / 1000
      if (this.detail) json.detail = this.detail
      return json
    },
    /**
     * 提交
     */
    addInfo () {
      addRecruits(this.setJson()).then(
        res => {
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success'
          })
          setTimeout(() => { this.routeTo() }, 1000)
        }
      )
    },
    /**
     * 提交
     */
    updateInfo () {
      updateRecruits(this.tipId, this.setJson()).then(
        res => {
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success'
          })
          setTimeout(() => { this.routeTo() }, 1000)
        }
      )
    },
    /**
     * 提交成功跳转
     */
    routeTo () {
      this.$router.push({ path: '/tips-admin/recruit/main' })
    }
  }
}
</script>
<style>
.quill-style .el-form-item__content {
  line-height: 1;
}
</style>
<style scoped>
.form-item-width {
  width: 194px;
}
</style>
